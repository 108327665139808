import {Button, Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import commonApi from '../../Services/CommonService';
import {
  Cart,
  ShareablesBlackIcon,
  cupcake,
  listIconGreen,
  rewardStar,
} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import CartSvg from '../../Svg/CartSvg';

import React, {useState, useEffect} from 'react';
// import SessionEvents from './SessionEvents';

import {DEFAULT_IMAGE} from '../../Utils/env';
import {successMessage} from '../../Utils/ToastMessages';
import {Pannels} from '../../Components';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {listIcon} from '../../Constants/Images';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import Slider from 'react-slick';
import CatalogFilter from '../../Components/Modals/CatalogFilter';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import CatalogDetail from '../../Components/Retail/CatalogDetail';
import {useLocation, useHistory} from 'react-router-dom';
import CreateCatalog from '../../Components/Catalog/CreateCatalog';
import {checkPermissions} from '../../Utils/commonUtils';
import {useSelector} from 'react-redux';

const Catalog = props => {
  const [showLoader, setshowLoader] = useState(false);

  const [showComponent, setShowComponent] = useState('');

  const [isCheck, setIsCheck] = useState([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10'
  ]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);

  const [tabType, setTabType] = useState('shop');
  const [ListView, setListView] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [catalogType, setCatalogType] = useState([]);
  const [favouriteCatalogs, setFavouriteCatalogs] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [orginalCartItems, setOrginalCartItems] = useState([]);
  // const [initial, setInitial] = useState(false);
  const [showModel, setshowModel] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [catalogDetail, setCatalogDetail] = useState({});
  const [page, setPage] = useState(1);

  const [isFetching, setIsFetching] = useState(false);

  const [catalogItems, setCatalogItems] = useState([]);
  const [catalogItemsTrending, setCatalogItemsTrending] = useState([]);

  const menuPermesions = useSelector(state => state.menuPermesionList);

  const history = useHistory();
  const locationWeb = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);
  let heartColor = '#52bd41';
  const productSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    afterChange: current => handleAfterChangeTrending(current),
    // adaptiveHeight: true,
  };

  const dynamicStyle = {
    padding: 0,
    // Add other styles as needed
  };

  const productSliderData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  const handleAfterChange = current => {
    if (current >= catalogItems.length - allProductSlider.slidesToShow) {
      // Call the API when the last slide is reached
      setIsFetching(true);
      if (tabType === 'shop') {
        getAllCatalog(searchQry, page + 1);
      }
    }
  };
  const handleAfterChangeTrending = current => {
    if (current >= catalogItems.length - allProductSlider.slidesToShow) {
      // Call the API when the last slide is reached
      setIsFetching(true);
      if (tabType === 'shop') {
        getAllCatalog(searchQry, page + 1, 'Tranding_ctalog_data');
      }
    }
  };

  const allProductSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    afterChange: current => handleAfterChange(current),
    // adaptiveHeight: true,
  };

  const allProductSliderData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);
  useEffect(() => {
    setshowLoader(true);
    setPage(1);
    getAllCatalog('', 1);

    getFavoritesCatalog('', 1);
    getCartCatalogs('', true, 1);

    handleBusinesProfileDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabType]);
  useEffect(() => {
    // setshowLoader(true);

    getCatalogsTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);
      
      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        showComponent !== ''
      ) {
        setShowComponent('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  

  useEffect(() => {
    setPage(1);
    if (tabType === 'shop') {
      getAllCatalog(searchQry, 1);
    }
    if (tabType === 'saved') {
      getFavoritesCatalog(searchQry, 1);
    }

    if (tabType === 'cart') {
      getCartCatalogs(searchQry, true, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQry]);

  const handleBusinesProfileDirection = () => {
    const {pathname, state} = locationWeb;

    if (state && state.typeIs === 'business_offerings') {
      // setCurrentComponent('sessions');
      // componentDecesion(state.meetup_item, 'session');
      const currentURL = window.location.href;
      const url = new URL(currentURL);

      const id = url.searchParams.get('id');

      getCatalogDetail(id);

      // setCatalogDetail(state.offering_item);
      // setShowComponent('retailDetailFromBusinessProfile');
      // history.replace({pathname: pathname, key: Date.now()});
    }
  };

  useEffect(()=>{
    setShowComponent('')
  },[tabType])

  const getCatalogDetail = id => {
    var payLoad = {
      id: id,
    };
    commonApi
      .get_catalog_by_id(payLoad)
      .then(res => {
        if (res.data) {
          setCatalogDetail(res.data);
          setShowComponent('retailDetailFromBusinessProfile');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Sales',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Clicks',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Abandonment',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Stale',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const getAllCatalog = (qry, pageNo, type) => {
    var payLoad = {
      catalog_type: isCheck && isCheck.length > 0 ? isCheck.toString() : '',
      search: qry ? qry : '',
      page: pageNo ? pageNo : page,
    };
    commonApi
      .get_all_catalogs(payLoad)
      .then(res => {
        let catdata = [];
        let trendData = [];
        if (pageNo > 1) {
          catdata = [...catalogItems, ...res.data];
          trendData = [...catalogItemsTrending, ...res.trending_catalog];
          if (type === 'Tranding_ctalog_data') {
            setCatalogItemsTrending(trendData);
          } else {
            setCatalogItems(catdata);
          }
        } else {
          catdata = res.data;
          trendData = res.trending_catalog;

          setCatalogItemsTrending(trendData);

          setCatalogItems(catdata);
        }

        setPage(pageNo ? pageNo : 1);
        setIsFetching(false);

        setTimeout(() => {
          setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getCatalogsTypes = () => {
    commonApi
      .get_catalogs_types()
      .then(res => {
        setCatalogType(res.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleCart = item => {
    if (
      item.catalog_type === 'equipment' ||
      item.catalog_type === 'material' ||
      item.catalog_type === 'fleet'
    ) {
      addToCart(item.catalog_id, item.replacement_cost);
    } else if (item.catalog_type === 'rewards') {
      addToCart(item.catalog_id, item.recipient_cost);
    } else {
      addToCart(item.catalog_id, item.cost);
    }
  };

  const addToCart = (catalogId, price, quantity) => {
    var payload = {
      // cart_id : cartId,
      catalog_id: catalogId,
      price: price,
      quantity: quantity ? quantity : '1',
    };
    commonApi
      .add_to_cart(payload)
      .then(res => {
        const newCartId = res.cart_id; // Assuming the cart_id is in res.data.cart_id

        setCatalogItems(previousItems =>
          previousItems.map(item =>
            item.catalog_id === catalogId
              ? {...item, cart_id: newCartId, is_cart: true}
              : item,
          ),
        );
        setFavouriteCatalogs(previousItems =>
          previousItems.map(item =>
            item.catalog_id === catalogId
              ? {...item, cart_id: newCartId, is_cart: true}
              : item,
          ),
        );
        successMessage(res.message);

        getCartCatalogs();
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const toggleFavouriteStatus = (items, catalogId) => {
    return items.map(item => {
      if (item.catalog_id === catalogId) {
        return {
          ...item,
          is_favourite: !item.is_favourite,
        };
      }
      return item;
    });
  };

  const AddToFavourite = catalogId => {
    var payload = {
      catalog_id: catalogId,
    };
    commonApi
      .favourite_catalog(payload)
      .then(res => {
        successMessage(res.message);
        setCatalogItems(previousItems =>
          toggleFavouriteStatus(previousItems, catalogId),
        );
        setCatalogItemsTrending(previousItems =>
          toggleFavouriteStatus(previousItems, catalogId),
        );
        setFavouriteCatalogs(previousItems =>
          removeCatalog(previousItems, catalogId),
        );

        // Optionally, fetch the catalog items again
        // getAllCatalog();
        // getFavoritesCatalog();
        // getCartCatalogs();
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const removeCatalogtem = catalogId => {
    var payload = {
      cart_id: catalogId.cart_id,
    };
    commonApi
      .add_to_cart(payload)
      .then(res => {
        successMessage(res.message);

        setCatalogItems(previousItems =>
          previousItems.map(item =>
            item.catalog_id === catalogId.catalog_id
              ? {...item, cart_id: '', is_cart: false}
              : item,
          ),
        );
        setFavouriteCatalogs(previousItems =>
          previousItems.map(item =>
            item.catalog_id === catalogId.catalog_id
              ? {...item, cart_id: '', is_cart: false}
              : item,
          ),
        );
        setCartItems(previousItems =>
          removeCatalogCart(previousItems, catalogId.catalog_id),
        );
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const removeCatalog = (items, catalogId) => {
    return items.filter(item => item.catalog_id !== catalogId);
  };
  const removeCatalogCart = (items, catalogId) => {
    return items.filter(item => item.catalog_id !== catalogId);
  };
  const getFavoritesCatalog = (qry, pageNo) => {
    var payLoad = {
      catalog_type: isCheck && isCheck.length > 0 ? isCheck.toString() : '',
      search: qry ? qry : '',
      page: pageNo ? pageNo : page,
    };
    commonApi
      .get_favorite_catalog(payLoad)
      .then(res => {
        let catSaveData = [];

        if (pageNo > 1) {
          catSaveData = [...favouriteCatalogs, ...res.data];
        } else {
          catSaveData = res.data;
        }
        setFavouriteCatalogs(catSaveData);
        setPage(pageNo ? pageNo : 1);
        setIsFetching(false);
        // setCatalogType(res.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCartCatalogs = (qry, filterCase, pageNo) => {
    var payLoad = {
      catalog_type: isCheck && isCheck.length > 0 ? isCheck.toString() : '',
      search: qry ? qry : '',
      page: pageNo ? pageNo : page,
    };
    commonApi
      .get_catalog_cart(payLoad)
      .then(res => {
        // setCatalogType(res.data);
        let catCarteData = [];

        if (pageNo > 1) {
          catCarteData = [...cartItems, ...res.data];
        } else {
          catCarteData = res.data;
        }
        setCartItems(catCarteData);
        setPage(pageNo ? pageNo : 1);
        setIsFetching(false);

        // if(!initial){
        //   setInitial(true)
        if (!filterCase) {
          setOrginalCartItems(res.data);
        }

        // }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const filterCatalogData = () => {
    if (tabType === 'shop') {
      getAllCatalog(searchQry, 1);
    }
    if (tabType === 'saved') {
      getFavoritesCatalog(searchQry, 1);
    }

    if (tabType === 'cart') {
      getCartCatalogs(searchQry, true, 1);
    }
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + 2 + scrollTop >= scrollHeight && !isFetching) {
      // setShowBottomSpinner(true);
      // Increment the page number and call the API
      setIsFetching(true);
      if (tabType === 'shop') {
        getAllCatalog(searchQry, page + 1);
      }
      if (tabType === 'cart') {
        getCartCatalogs(searchQry, true, page + 1);
      }
      if (tabType === 'saved') {
        getFavoritesCatalog(searchQry, page + 1);
      }
    }
  };

  const OfferingsPermissions =
    menuPermesions && checkPermissions(menuPermesions, 'Catalog');

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />

        <div className="page-content-wrapper catalog_page">
          <div className="sessions-list-wrapper slider-screen-main">
            {OfferingsPermissions &&
              (OfferingsPermissions === 'allow' ||
                OfferingsPermissions?.analytics_row === true) && (
                <div className="Reward_main analytics_slider_bg">
                  <span
                    className="analytics_slider_heading "
                    style={{color: analycticsSlider ? '' : '#999999'}}
                    onClick={() => {
                      setAnalycticsSlider(!analycticsSlider);
                    }}>
                    Analytics Row
                  </span>

                  {analycticsSlider && (
                    <div className="reward-top mt-3 mb-2">
                      <Slider {...rewardSlider}>
                        {rewardSliderData.length > 0 &&
                          rewardSliderData.map((item, index) => (
                            <div className="reward-main">
                              <div className="reward-card">
                                <span
                                  style={{
                                    color: '#000',

                                    fontFamily: 'Inter',
                                    // fontSize: '10.833px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: 'normal',
                                  }}>
                                  {item.name}
                                </span>
                                <span
                                  style={{
                                    color: '#000',

                                    fontFamily: 'Inter',
                                    fontSize: '17px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                  }}>
                                  {item.money}
                                  {item.name === 'Rating' && (
                                    <img src={rewardStar} alt="" />
                                  )}
                                  <i
                                    class="fa-solid fa-arrow-up"
                                    style={{color: '#52bd41'}}></i>
                                </span>
                                <div
                                  class="message-date-info"
                                  style={
                                    {
                                      // marginLeft: '13px',
                                    }
                                  }>
                                  <span
                                    class="message-date"
                                    style={{
                                      color: '#333',
                                      fontFamily: 'Helvetica',
                                      // fontSize: '9px',
                                      fontStyle: 'normal',
                                      fontWeight: '300',
                                      lineHeight: 'normal',
                                    }}>
                                    {item.time}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  )}
                </div>
              )}
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="customer-search-page primary_panel_wraper referal_page_content">
                    <div className="upload-file-top mb-3">
                      <h1
                        style={{
                          color: '#000',
                          fontFamily: 'Inter',
                          fontSize: '21.062px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          lineHeight: 'normal',
                        }}>
                        Hey, {localStorage.getItem('firstName')}
                      </h1>
                      <div className="list_icon_flex mt-2">
                        <div
                          className=""
                          style={{
                            color: '#726F6F',
                            fontFamily: 'Inter',
                            fontSize: '16.047px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            // lineHeight: 'normal',
                          }}>
                          Have fun shopping!
                        </div>

                        {!ListView && tabType === 'shop' && (
                          <div
                            className="as-link"
                            onClick={() => {
                              setListView(!ListView);
                            }}>
                            <img src={listIcon} alt="" />
                          </div>
                        )}
                        {ListView && tabType === 'shop' && (
                          <div
                            className="as-link"
                            onClick={() => {
                              setListView(!ListView);
                            }}>
                            <img src={listIconGreen} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="messages-header  mb-3">
                      <div className="event-tabs tabs-style-2 mb-0">
                        <ul>
                          <li className="d-inline-flex">
                            <Button
                              className={tabType === 'shop' ? 'active' : ''}
                              onClick={() => {
                                setTabType('shop');
                                setSearchQry('');
                                setIsCheck([
                                  '1',
                                  '2',
                                  '3',
                                  '4',
                                  '5',
                                  '6',
                                  '7',
                                  '8',
                                  '9',
                                  '10'
                                ]);
                              }}>
                              Shop
                            </Button>
                          </li>
                          <li className="d-inline-flex">
                            <Button
                              className={tabType === 'saved' ? 'active' : ''}
                              onClick={() => {
                                setTabType('saved');
                                setSearchQry('');
                                setListView(false);
                                setIsCheck([
                                  '1',
                                  '2',
                                  '3',
                                  '4',
                                  '5',
                                  '6',
                                  '7',
                                  '8',
                                  '9',
                                  '10'
                                ]);
                              }}>
                              Saved
                            </Button>
                          </li>
                          <li className="cart_count_main">
                            <Button
                              className={tabType === 'cart' ? 'active' : ''}
                              onClick={() => {
                                setTabType('cart');
                                setSearchQry('');
                                setListView(false);
                                setIsCheck([
                                  '1',
                                  '2',
                                  '3',
                                  '4',
                                  '5',
                                  '6',
                                  '7',
                                  '8',
                                  '9',
                                  '10'
                                ]);
                              }}>
                              Cart
                            </Button>
                            {cartItems.length > 0 && (
                              <div className="cart_count">
                                {cartItems.length}
                              </div>
                            )}
                          </li>

                          {/* <li>
                            <Button
                              className={tabType === 'vendors' ? 'active' : ''}
                              onClick={() => {
                                setTabType('vendors');
                              }}>
                              Vendors
                            </Button>
                          </li> */}
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        {OfferingsPermissions &&
                          (OfferingsPermissions === 'allow' ||
                            OfferingsPermissions?.create === true) && (
                            <span
                              className={` mt-0
                                ${
                                  showComponent === 'createCatalog'
                                    ? 'icon active'
                                    : 'icon'
                                }
                              `}
                              onClick={() => {
                                setShowComponent('createCatalog');
                              }}>
                              <i className="icon-plus" />
                            </span>
                          )}

                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={` mt-0
                           ${
                             threeDots
                               ? 'icon active threeDots'
                               : 'icon threeDots'
                           }`}>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <>
                        <div className="person-search-wrapper mb-3 mt-0 messages-users-wrapper">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                setshowModel('filter');
                              }}>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="refferal-list-wrapper">
                      {showLoader && <LoaderSpinner />}

                      {!ListView ? (
                        <>
                          {tabType === 'shop' && (
                            <>
                              <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide onScroll={handleScroll}>
                                {catalogItems && Object.entries(catalogItems)?.length > 0 ? (
                                  catalogItems.map((item, index) => (
                                    <div className="mb-3 payment-card-custom-block">
                                      <div className="payment-card qa-list-item">
                                        <div
                                          className="payment-user-item  as-link"
                                          onClick={() => {
                                            setCatalogDetail(item);
                                            setShowComponent('retailDetail');
                                          }}>
                                          <img
                                            className="cart-img"
                                            src={
                                              item.image
                                                ? item.image
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />

                                          <div className="user-content as-link">
                                            <h2
                                            className='p-reg-truncate'
                                              style={{
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '15.044px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: 'normal',
                                              }}>
                                              {item.title}
                                            </h2>
                                            <p
                                              style={{
                                                fontWeight: '500 !important',
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '13.038px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                              }}>
                                              In-Stock
                                            </p>
                                            <div className="message-date-info">
                                              <span className="message-date">
                                                <time datetime="1699009603000">
                                                  {item.catalog_type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    item.catalog_type.slice(1)}
                                                </time>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="message-other-users"></div>
                                        </div>

                                        <div className="">
                                          <div className="payment-cart-subCard as-link">
                                            <span
                                              className="font-bold"
                                              onClick={() => {
                                                setCatalogDetail(item);
                                                setShowComponent(
                                                  'retailDetail',
                                                );
                                              }}>
                                              {item.cost
                                                ? `$${item.cost}`
                                                : 'Free'}
                                            </span>

                                            {item.is_cart == true && (
                                              <span
                                                className="font-bold pl-5"
                                                onClick={() => {
                                                  setCatalogDetail(item);
                                                  setShowComponent(
                                                    'retailDetail',
                                                  );
                                                }}>
                                                Added to Card
                                              </span>
                                            )}

                                            <div className="buy-btn-wrapper">
                                              <span
                                                onClick={() => {
                                                  AddToFavourite(
                                                    item.catalog_id,
                                                  );
                                                }}
                                                className="as-link">
                                                <i
                                                  className={`${
                                                    item.is_favourite
                                                      ? 'fas'
                                                      : 'far'
                                                  } fa-heart`}
                                                  style={{
                                                    fontSize: '21px',
                                                    color: item.is_favourite
                                                      ? heartColor
                                                      : '',
                                                  }}></i>
                                              </span>
                                              <span
                                                style={{
                                                  paddingLeft: '17px',
                                                }}>
                                                <img
                                                  className="sharableYellow as-link"
                                                  src={ShareablesBlackIcon}
                                                  alt="shareable"
                                                />
                                              </span>

                                              {item.is_cart ? (
                                                <span
                                                  className="as-link"
                                                  style={{
                                                    paddingLeft: '17px',
                                                    paddingRight: '2px',
                                                  }}
                                                  onClick={() => {
                                                    removeCatalogtem(item);
                                                  }}>
                                                  <i
                                                    className="fa-solid fa-xmark"
                                                    style={{
                                                      color: '#D71616',
                                                      fontSize: '21px',
                                                    }}></i>
                                                </span>
                                              ) : (
                                                <span
                                                  className="as-link"
                                                  onClick={() => {
                                                    handleCart(item);
                                                  }}
                                                  style={{
                                                    paddingLeft: '17px',
                                                    paddingRight: '2px',
                                                  }}>
                                                  <CartSvg fill={'#52BD41'} />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="no-results-wrapper mt-5">
                                    <div className="no-results-content mt-5">
                                      <span>
                                        {' '}
                                        {
                                          labels.no_offering_found[
                                            selected_lang
                                          ]
                                        }
                                      </span>
                                      {OfferingsPermissions &&
                                        (OfferingsPermissions === 'allow' ||
                                          (OfferingsPermissions?.create ===
                                            true && (
                                            <div className="btn-wrapper mt-3">
                                              <Button
                                                style={{padding: '11px 25px'}}
                                                className="btn btn-dark"
                                                onClick={() => {
                                                  setShowComponent(
                                                    'createCatalog',
                                                  );
                                                }}>
                                                Create Offerings
                                              </Button>
                                            </div>
                                          )))}
                                    </div>
                                  </div>
                                )}
                              </Scrollbars>
                            </>
                          )}

                          {tabType === 'saved' && (
                            <>
                              <Scrollbars autoHide onScroll={handleScroll}>
                                {Object.entries(favouriteCatalogs).length >
                                0 ? (
                                  favouriteCatalogs.map((item, index) => (
                                    <div className="mb-3">
                                      <div className="payment-card qa-list-item">
                                        <div
                                          className="payment-user-item as-link"
                                          onClick={() => {
                                            setCatalogDetail(item);
                                            setShowComponent('retailDetail');
                                          }}>
                                          <img
                                            className="cart-img"
                                            src={
                                              item.image
                                                ? item.image
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />

                                          <div className="user-content as-link">
                                            <h2
                                            className='p-reg-truncate'
                                              style={{
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '15.044px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: 'normal',
                                              }}>
                                              {item.title}
                                            </h2>
                                            <p
                                              style={{
                                                fontWeight: '500 !important',
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '13.038px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                              }}>
                                              In-Stock
                                            </p>
                                            <div className="message-date-info">
                                              <span className="message-date">
                                                <time datetime="1699009603000">
                                                  {item.catalog_type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    item.catalog_type.slice(1)}
                                                </time>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="message-other-users"></div>
                                        </div>

                                        <div className="">
                                          <div className="payment-cart-subCard">
                                            <span
                                              className="font-bold as-link"
                                              onClick={() => {
                                                setCatalogDetail(item);
                                                setShowComponent(
                                                  'retailDetail',
                                                );
                                              }}>
                                              {item.cost
                                                ? `$${item.cost}`
                                                : 'Free'}
                                            </span>

                                            {item.is_cart && (
                                              <span
                                                className="font-bold pl-5 as-link"
                                                onClick={() => {
                                                  setCatalogDetail(item);
                                                  setShowComponent(
                                                    'retailDetail',
                                                  );
                                                }}>
                                                Added to Card
                                              </span>
                                            )}

                                            <div className="buy-btn-wrapper">
                                              <span
                                                onClick={() => {
                                                  AddToFavourite(
                                                    item.catalog_id,
                                                  );
                                                }}
                                                className="as-link">
                                                <i
                                                  className={`${
                                                    item.is_favourite
                                                      ? 'fas'
                                                      : 'far'
                                                  } fa-heart`}
                                                  style={{
                                                    fontSize: '21px',
                                                    color: item.is_favourite
                                                      ? heartColor
                                                      : '',
                                                  }}></i>
                                              </span>
                                              <span
                                                style={{
                                                  paddingLeft: '17px',
                                                }}>
                                                <img
                                                  className="sharableYellow as-link"
                                                  src={ShareablesBlackIcon}
                                                  alt="shareable"
                                                />
                                              </span>

                                              {item.is_cart ? (
                                                <span
                                                  className="as-link"
                                                  style={{
                                                    paddingLeft: '17px',
                                                    paddingRight: '2px',
                                                  }}
                                                  onClick={() => {
                                                    removeCatalogtem(item);
                                                  }}>
                                                  <i
                                                    className="fa-solid fa-xmark"
                                                    style={{
                                                      color: '#D71616',
                                                      fontSize: '21px',
                                                    }}></i>
                                                </span>
                                              ) : (
                                                <span
                                                  className="as-link"
                                                  onClick={() => {
                                                    handleCart(item);
                                                  }}
                                                  style={{
                                                    paddingLeft: '17px',
                                                    paddingRight: '2px',
                                                  }}>
                                                  <CartSvg fill={'#52BD41'} />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="no-results-wrapper mt-5">
                                    <div className="no-results-content mt-5">
                                      <span>
                                        {' '}
                                        {
                                          labels.no_offering_found[
                                            selected_lang
                                          ]
                                        }
                                      </span>
                                      {OfferingsPermissions &&
                                        (OfferingsPermissions === 'allow' ||
                                          (OfferingsPermissions?.create ===
                                            true && (
                                            <div className="btn-wrapper mt-3">
                                              <Button
                                                style={{padding: '11px 25px'}}
                                                className="btn btn-dark"
                                                onClick={() => {
                                                  setShowComponent(
                                                    'createCatalog',
                                                  );
                                                }}>
                                                Create Offerings
                                              </Button>
                                            </div>
                                          )))}
                                    </div>
                                  </div>
                                )}
                              </Scrollbars>
                            </>
                          )}

                          {tabType === 'cart' && (
                            <>
                              <Scrollbars autoHide onScroll={handleScroll}>
                                {Object.entries(cartItems).length > 0 ? (
                                  cartItems.map((item, index) => (
                                    <div className="mb-3">
                                      <div className="payment-card qa-list-item">
                                        <div
                                          className="payment-user-item as-link"
                                          onClick={() => {
                                            setCatalogDetail(item);
                                            setShowComponent('retailDetail');
                                          }}>
                                          <img
                                            className="cart-img"
                                            src={
                                              item.image
                                                ? item.image
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />

                                          <div className="user-content as-link">
                                            <h2
                                            className='p-reg-truncate'
                                              style={{
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '15.044px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: 'normal',
                                              }}>
                                              {item.title}
                                            </h2>
                                            <p
                                              style={{
                                                fontWeight: '500 !important',
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '13.038px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                              }}>
                                              In-Stock
                                            </p>
                                            <div className="message-date-info">
                                              <span className="message-date">
                                                <time datetime="1699009603000">
                                                  {item.catalog_type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    item.catalog_type.slice(1)}
                                                </time>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="message-other-users"></div>
                                        </div>

                                        <div className="">
                                          <div className="payment-cart-subCard">
                                            <span
                                              className="font-bold as-link"
                                              onClick={() => {
                                                setCatalogDetail(item);
                                                setShowComponent(
                                                  'retailDetail',
                                                );
                                              }}>
                                              {item.cost
                                                ? `$${item.cost}`
                                                : 'Free'}
                                            </span>

                                            <div className="buy-btn-wrapper">
                                              <span
                                                onClick={() => {
                                                  AddToFavourite(
                                                    item.catalog_id,
                                                  );
                                                }}
                                                className="as-link">
                                                <i
                                                  className={`${
                                                    item.is_favourite
                                                      ? 'fas'
                                                      : 'far'
                                                  } fa-heart`}
                                                  style={{
                                                    fontSize: '21px',
                                                    color: item.is_favourite
                                                      ? heartColor
                                                      : '',
                                                  }}></i>
                                              </span>
                                              <span
                                                style={{
                                                  paddingLeft: '17px',
                                                }}>
                                                <img
                                                  className="sharableYellow as-link"
                                                  src={ShareablesBlackIcon}
                                                  alt="shareable"
                                                />
                                              </span>

                                              {item.is_cart ? (
                                                <span
                                                  className="as-link"
                                                  style={{
                                                    paddingLeft: '17px',
                                                    paddingRight: '2px',
                                                  }}
                                                  onClick={() => {
                                                    removeCatalogtem(item);
                                                  }}>
                                                  <i
                                                    className="fa-solid fa-xmark"
                                                    style={{
                                                      color: '#D71616',
                                                      fontSize: '21px',
                                                    }}></i>
                                                </span>
                                              ) : (
                                                <span
                                                  className="as-link"
                                                  onClick={() => {
                                                    handleCart(item);
                                                  }}
                                                  style={{
                                                    paddingLeft: '17px',
                                                    paddingRight: '2px',
                                                  }}>
                                                  <CartSvg fill={'#52BD41'} />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="no-results-wrapper mt-5">
                                    <div className="no-results-content mt-5">
                                      <span>
                                        {' '}
                                        {
                                          labels.no_offering_found[
                                            selected_lang
                                          ]
                                        }
                                      </span>

                                      {OfferingsPermissions &&
                                        (OfferingsPermissions === 'allow' ||
                                          (OfferingsPermissions?.create ===
                                            true && (
                                            <div className="btn-wrapper mt-3">
                                              <Button
                                                style={{padding: '11px 25px'}}
                                                className="btn btn-dark"
                                                onClick={() => {
                                                  setShowComponent(
                                                    'createCatalog',
                                                  );
                                                }}>
                                                Create Offerings
                                              </Button>
                                            </div>
                                          )))}
                                    </div>
                                  </div>
                                )}
                              </Scrollbars>
                            </>
                          )}
                        </>
                      ) : (
                        <Scrollbars autoHide>
                          <div>
                            {tabType === 'shop' && (
                              <div className="product-listing">
                                {catalogItemsTrending &&
                                  catalogItemsTrending.length > 0 && (
                                    <>
                                      <h1
                                        className="mb-2"
                                        style={{
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '700',
                                          lineHeight: 'normal',
                                        }}>
                                        Trending
                                      </h1>

                                      <Slider {...productSlider}>
                                        {catalogItemsTrending.length > 0 &&
                                          catalogItemsTrending.map(
                                            (item, index) => (
                                              <div key={index}>
                                                <div className="trending-product-item mr-3">
                                                  <div
                                                    className="trending-product-img as-link"
                                                    onClick={() => {
                                                      setCatalogDetail(item);
                                                      setShowComponent(
                                                        'retailDetail',
                                                      );
                                                    }}>
                                                    <img
                                                      src={item.image}
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div
                                                    className="as-link"
                                                    onClick={() => {
                                                      setCatalogDetail(item);
                                                      setShowComponent(
                                                        'retailDetail',
                                                      );
                                                    }}
                                                    style={{
                                                      color: '#000',
                                                      fontFamily: 'Inter',
                                                      fontSize: '15px',
                                                      fontStyle: 'normal',
                                                      fontWeight: '600',
                                                      lineHeight: 'normal',
                                                    }}>
                                                    {item.title}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: '240px',
                                                      height: '37px',
                                                      borderRadius: '3px',
                                                      background: '#F1EFE8',
                                                      padding: '6px',
                                                    }}
                                                    className="d-flex justify-content-between  as-link">
                                                    <span
                                                      onClick={() => {
                                                        setCatalogDetail(item);
                                                        setShowComponent(
                                                          'retailDetail',
                                                        );
                                                      }}
                                                      style={{
                                                        color: '#000',
                                                        fontFamily: 'Inter',
                                                        fontSize: '15px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal',
                                                      }}>
                                                      {item.cost
                                                        ? `Starting $${item.cost}`
                                                        : 'Free'}
                                                    </span>
                                                    <span
                                                      onClick={() => {
                                                        AddToFavourite(
                                                          item.catalog_id,
                                                        );
                                                      }}
                                                      className="as-link">
                                                      <i
                                                        className={`${
                                                          item.is_favourite
                                                            ? 'fas'
                                                            : 'far'
                                                        } fa-heart`}
                                                        style={{
                                                          fontSize: '21px',
                                                          color:
                                                            item.is_favourite
                                                              ? heartColor
                                                              : '',
                                                        }}></i>
                                                    </span>
                                                    <span>
                                                      {item.is_cart ? (
                                                        <span
                                                          className="as-link"
                                                          style={{}}
                                                          onClick={() => {
                                                            removeCatalogtem(
                                                              item,
                                                            );
                                                          }}>
                                                          <i
                                                            className="fa-solid fa-xmark"
                                                            style={{
                                                              color: '#D71616',
                                                              fontSize: '21px',
                                                            }}></i>
                                                        </span>
                                                      ) : (
                                                        <span
                                                          className="as-link"
                                                          onClick={() => {
                                                            handleCart(item);
                                                          }}
                                                          style={{}}>
                                                          <CartSvg
                                                            fill={'#52BD41'}
                                                          />
                                                        </span>
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            ),
                                          )}
                                      </Slider>
                                    </>
                                  )}
                                {catalogItems.length > 0 && (
                                  <h1
                                    className=" mt-4 mb-2"
                                    style={{
                                      color: '#000',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '700',
                                      lineHeight: 'normal',
                                    }}>
                                    All Products
                                  </h1>
                                )}

                                <Slider {...allProductSlider}>
                                  {catalogItems.length > 0 &&
                                    catalogItems.map((item, index) => (
                                      <div>
                                        <div
                                          className="all-product-item mr-1 as-link"
                                          onClick={() => {
                                            setCatalogDetail(item);
                                            setShowComponent('retailDetail');
                                          }}>
                                          <div className="all-product-img">
                                            <img src={item.image} alt="" />
                                          </div>

                                          <span
                                            style={{
                                              color: '#000',
                                              fontFamily: 'Inter',
                                              fontSize: '15px',
                                              fontStyle: 'normal',
                                              fontWeight: '600',
                                              lineHeight: 'normal',
                                            }}>
                                            {item.title} <b></b>
                                          </span>
                                          <span
                                            style={{
                                              color: '#000',
                                              fontFamily: 'Inter',
                                              fontSize: '15px',
                                              fontStyle: 'normal',
                                              fontWeight: '500',
                                              lineHeight: 'normal',
                                            }}>
                                            {item.cost
                                              ? `Starting $${item.cost}`
                                              : 'Free'}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                </Slider>
                              </div>
                            )}
                          </div>
                        </Scrollbars>
                      )}
                    </div>
                  </div>
                  {showModel === 'filter' && (
                    <CatalogFilter
                      // show={searchFilter}
                      onClose={() => setshowModel('')}
                      //  sessionType={sessionType}
                      // isEvent={isEvent}
                      hitFuctionAfterFilter={filterCatalogData}
                      filterlisting={catalogType}
                      isCheckAll={isCheckAll}
                      setIsCheckAll={setIsCheckAll}
                      isCheck={isCheck}
                      setIsCheck={setIsCheck}
                      favouriteCatalogs={favouriteCatalogs}
                      cartItems={cartItems}
                      orginalCartItem={orginalCartItems}
                      catalogItems={catalogItems}
                      heartColor={heartColor}
                    />
                  )}
                </>
              }
              secondary={
                <>
                  {showComponent === 'createCatalog' && (
                    <CreateCatalog
                      onClose={() => {
                        setShowComponent('');
                      }}
                      onCloseWithApiHit={() => {
                        setShowComponent('');
                        setPage(1);
                        getAllCatalog('', 1);

                        getFavoritesCatalog('', 1);
                        getCartCatalogs('', true, 1);
                      }}
                 
                    />
                  )}
                  {showComponent === 'retailDetail' && (
                    <CatalogDetail
                      catalogDetail_id={catalogDetail.catalog_id}
                      catalogItems={tabType === 'saved'? favouriteCatalogs : tabType ==='cart' ? cartItems :  catalogItems}
                      setCatalogItems={setCatalogItems}
                      AddToFavourite={AddToFavourite}
                      removeCatalogtem={removeCatalogtem}
                      handleCart={handleCart}
                      onClose={() => {
                        setShowComponent('');
                      }}
                      OfferingsPermissions={OfferingsPermissions}
                      searchQry={searchQry}
                    />
                  )}

                  {showComponent === 'retailDetailFromBusinessProfile' && (
                    <CatalogDetail
                      catalogItem={catalogDetail}
                      // catalogItems={catalogItems}
                      AddToFavourite={AddToFavourite}
                      removeCatalogtem={removeCatalogtem}
                      handleCart={handleCart}
                      onClose={() => {
                        setShowComponent('');
                      }}
                      OfferingsPermissions={OfferingsPermissions}
                    />
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Catalog;
